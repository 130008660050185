<button mat-menu-item style="filter: none"
        [style.background-color]="note.backgroundColor"
        [style.color]="note.foregroundColor"
        [cbHighlightColor]="note.backgroundColorGradient"
        (click)="copy()"
        (contextmenu)="showContextMenu($event)"
        role="menuitem"
        data-cy="small-note">
  {{ !note.header ? note.content : note.header }}
</button>

<div [matMenuTriggerFor]="contextMenu"
     style="visibility: hidden; position: fixed">
</div>
<mat-menu #contextMenu="matMenu">
  <div [style.background]="note.backgroundColorGradient
            ? 'linear-gradient(to bottom, ' + note.backgroundColor + ', ' + note.backgroundColorGradient
            : note.backgroundColor"
       [style.color]="note.foregroundColor">
    <button (click)="edit()" mat-menu-item
            [attr.aria-label]="'EDIT' | translate">
      <mat-icon>edit</mat-icon>
      {{ 'EDIT' | translate }}
    </button>
    <button (click)="delete()" mat-menu-item
            [attr.aria-label]="'DELETE' | translate">
      <mat-icon>delete</mat-icon>
      {{ 'DELETE' | translate }}
    </button>
    <ng-container *ngIf="dataService.getColorizedObjects(note).length > 1">
      <button [matMenuTriggerFor]="items" mat-menu-item
              [attr.aria-label]="'COPY_COLOR_FROM' | translate">
        <mat-icon>colorize</mat-icon>
        {{ 'COPY_COLOR_FROM' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="dataService.getColorizedObjects(note).length === 1">
      <button (click)="copyColorFrom(dataService.getColorizedObjects(note)[0])" mat-menu-item
              [attr.aria-label]="'COPY_OTHER_COLOR' | translate">
        <mat-icon>colorize</mat-icon>
        {{ 'COPY_OTHER_COLOR' | translate }}
      </button>
    </ng-container>
    <button (click)="copy()" mat-menu-item
            [attr.aria-label]="'COPY_VALUE' | translate">
      <mat-icon>content_copy</mat-icon>
      {{ 'COPY_VALUE' | translate }}
    </button>
  </div>
</mat-menu>

<mat-menu #items="matMenu">
  <ng-container *ngFor="let item of dataService.getColorizedObjects(note)">
    <button (click)="copyColorFrom(item)" *ngIf="note !== item" [style.background]="item.backgroundColorGradient
                ? 'linear-gradient(to bottom, ' + item.backgroundColor + ', ' + item.backgroundColorGradient
                : item.backgroundColor"
            [style.color]="item.foregroundColor"
            mat-menu-item [attr.aria-label]="'COPY_COLOR_FROM' | translate">
      <mat-icon>color_lens</mat-icon>
      <ng-container *ngIf="!item.backgroundColorGradient">
        {{item.backgroundColor}}
      </ng-container>
      <ng-container *ngIf="item.backgroundColorGradient">
        {{ item.backgroundColor + ' → ' + item.backgroundColorGradient }}
      </ng-container>
    </button>
  </ng-container>
</mat-menu>
