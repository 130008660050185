<h2>{{ 'DIALOG_TITLE_IMPORT' | translate }}</h2>
<p>{{ 'DIALOG_TEXT_IMPORT' | translate }}</p>
<div class="button-group">
  <button (click)="cancel()" mat-button style="margin-right: 16px">
    {{ 'CANCEL' | translate }}
  </button>
  <button (click)="import()" color="warn" mat-raised-button>
    {{ 'IMPORT' | translate }}
  </button>
</div>
<div class="hashy-icon-wrapper">
  <cb-hashy></cb-hashy>
</div>
