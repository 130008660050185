<div [style.padding.px]="16">
  <h1 mat-dialog-title class="flex-container"
      cdkDrag cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane">
      <span>{{'EDIT_IMAGE' | translate}}</span>
      <span style="
        color: var(--color-transparency-medium);
        font-family: monospace;
        font-size: medium;
      ">{{fileType}}</span>
  </h1>
  <div mat-dialog-content style="margin: 0; padding: 0">
    <div class="form-group-wrapper">
      <mat-form-field color="accent">
        <mat-label>{{ 'POSITION_X' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.posX" type="number">
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>{{ 'POSITION_Y' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.posY" type="number">
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>{{ 'POSITION_Z' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.posZ" type="number">
      </mat-form-field>
    </div>

    <mat-form-field color="accent" [style.width]="'100%'">
      <mat-label>{{ 'HEADER' | translate }}</mat-label>
      <input data-cy="edit-note-dialog-header" matInput [(ngModel)]="data.header">
    </mat-form-field>

    <mat-form-field *ngIf="!uploaded" color="accent" [style.width]="'100%'">
      <mat-label>{{ 'IMAGE_LINK' | translate }}</mat-label>
      <input matInput cbAutofocus
             [(ngModel)]="data.source"
             (input)="loadedFromUrl = false">
      <button *ngIf="data.source"
              matSuffix mat-icon-button aria-label="clear"
              (click)="data.source = null; loadedFromUrl = false">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <section *ngIf="uploaded; else url" class="img-container">
      <a class="img" *ngIf="showPreview; else icon">
        <img
          [src]="uploaded"
          class="animated"
          height="140"
          alt="loading failed"
          style="border-radius: 8px">
        <button class="round-button"
                (click)="removeImage()" mat-icon-button
                [attr.aria-label]="'DELETE' | translate">
          <mat-icon>close</mat-icon>
        </button>
      </a>
      <ng-template #icon>
        <a class="img file-type-icon">
          <mat-icon class="icon">{{matIcon}}</mat-icon>
          <button class="round-button"
                  (click)="removeImage()" mat-icon-button
                  [attr.aria-label]="'DELETE' | translate">
            <mat-icon>close</mat-icon>
          </button>
        </a>
      </ng-template>
    </section>
    <ng-template #url>
      <section class="img-container">
        <button *ngIf="!loadedFromUrl"
                class="upload-button"
                (click)="openFileDialog()"
                mat-flat-button
                [attr.aria-label]="'UPLOAD_FILE' | translate">
          <mat-icon>file_upload</mat-icon>
          <span style="margin-left: 20px">{{'UPLOAD_FILE' | translate}}</span>
        </button>
        <img
          (load)="onUrlLoaded()"
          [class.hide]="!loadedFromUrl"
          [src]="data.source"
          alt="loading failed"
          class="animated"
          height="140"
          style="border-radius: 8px">
      </section>
    </ng-template>
  </div>

  <div class="dialog-button-group">
    <button mat-button (click)="cancel()">
      {{ 'CANCEL' | translate }}
    </button>
    <button [disabled]="!data.source && !uploaded"
            mat-button (click)="submit()" class="ok-button">
      {{ 'OK' | translate }}
    </button>
  </div>
</div>
