<div cbCursor class="app"
     [class.loading]="!initialized"
     [class.windows]="settings.isWindows"
     [class.macos]="settings.isMacos"
     [class.linux]="settings.isLinux"
     [class.tauri]="settings.isTauri">
  <mat-tab-group animationDuration="0" [(selectedIndex)]="tabIndex">
    <mat-tab *ngFor="let tab of dataService.tabs; let i = index">
      <ng-template mat-tab-label>
        <div class="tab-header-container"
             [id]="i.toString()"
             cdkDropList
             cdkDropListOrientation="horizontal"
             (cdkDropListDropped)="dropTab($event)"
             [cdkDropListConnectedTo]="getAllListConnections(i)">
          <div class="tab-header" cdkDrag cdkDragRootElement=".mat-tab-label"
               [class.warning]="dataService.hasWarning(tab.index)"
               [class.error]="dataService.hasError(tab.index)"
               [attr.data-cy]="tabIndex === tab.index ? 'tab-header-active' : 'tab-header'">
            {{ tab.label ? tab.label : '#Board ' + (tab.index + 1) }}
          </div>
          <ng-container *ngIf="tabIndex === tab.index">
            <mat-icon
              [attr.aria-label]="'EDIT_TAB' | translate"
              [matTooltip]="'EDIT_TAB' | translate"
              class="first tab-button"
              (click)="openEditTabDialog()">
              edit
            </mat-icon>
            <ng-container *ngIf="dataService.tabs.length > 1">
              <mat-icon
                [attr.aria-label]="'CLOSE_TAB' | translate"
                [matTooltip]="'CLOSE_TAB' | translate"
                class="tab-button"
                (click)="dataService.removeCurrentTab()">
                close
              </mat-icon>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <cb-tab
        (contextmenu)="showContextMenu($event)"
        [tab]="tab"
        [draggableChanged]="draggableChanged"
      ></cb-tab>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="dataService.addTab(); $event.stopPropagation()"
             style="padding: 24px; margin: -24px">
          <mat-icon
            [attr.aria-label]="'NEW_TAB' | translate"
            [matTooltip]="'NEW_TAB' | translate"
            style="transform: translateY(2px)">
            add
          </mat-icon>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div [matMenuTriggerFor]="contextMenu"
       [style.left.px]="rightClickPosX"
       [style.top.px]="rightClickPosY"
       style="visibility: hidden; position: fixed">
  </div>
  <mat-menu #contextMenu="matMenu">
    <ng-container *ngIf="dataService.selectedItemsCount as n">
      <button (click)="copySelectedItems()" mat-menu-item [attr.aria-label]="'COPY_N_ITEMS' | translate : {n}">
        <mat-icon>content_copy</mat-icon>
        {{ 'COPY_N_ITEMS' | translate : {n} }}
      </button>

      <button (click)="cutSelectedItems()" mat-menu-item [attr.aria-label]="'CUT_N_ITEMS' | translate : {n}">
        <mat-icon>content_cut</mat-icon>
        {{ 'CUT_N_ITEMS' | translate : {n} }}
      </button>

      <button (click)="deleteSelectedItems()" mat-menu-item [attr.aria-label]="'DELETE_N_ITEMS' | translate : {n}">
        <mat-icon>delete</mat-icon>
        {{ 'DELETE_N_ITEMS' | translate : {n} }}
      </button>

      <button (click)="saveTabOrSelection()" mat-menu-item [attr.aria-label]="'SAVE_N_ITEMS' | translate : {n}">
        <mat-icon>save</mat-icon>
        {{ 'SAVE_N_ITEMS' | translate : {n} }}
      </button>
    </ng-container>

    <ng-container *ngIf="!dataService.selectedItemsCount">
      <ng-container *ngIf="canPasteItems">
        <button (click)="dataService.importItemsFromClipboard()" mat-menu-item [attr.aria-label]="'PASTE_ITEMS' | translate">
          <mat-icon>content_paste_go</mat-icon>
          {{ 'PASTE_ITEMS' | translate }}
        </button>

        <mat-divider></mat-divider>
      </ng-container>

      <button (click)="openNewNoteDialog()" mat-menu-item [attr.aria-label]="'CREATE_NOTE' | translate">
        <mat-icon>note</mat-icon>
        {{ 'CREATE_NOTE' | translate }}
      </button>

      <button (click)="openNewNoteListDialog()" mat-menu-item [attr.aria-label]="'CREATE_NOTE_LIST' | translate">
        <mat-icon>sticky_note_2</mat-icon>
        {{ 'CREATE_NOTE_LIST' | translate }}
      </button>

      <button (click)="openNewTaskListDialog()" mat-menu-item [attr.aria-label]="'CREATE_TASK_LIST' | translate">
        <mat-icon>list</mat-icon>
        {{ 'CREATE_TASK_LIST' | translate }}
      </button>

      <button (click)="openNewImageDialog()" mat-menu-item [attr.aria-label]="'CREATE_IMAGE' | translate">
        <mat-icon>image</mat-icon>
        {{ 'CREATE_IMAGE' | translate }}
      </button>

      <mat-divider></mat-divider>

      <ng-container *ngIf="redoService.undoPossible | async">
        <button (click)="dataService.undo()" mat-menu-item [attr.aria-label]="'UNDO_LAST_ACTION' | translate">
          <mat-icon>undo</mat-icon>
          {{ 'UNDO_LAST_ACTION' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="redoService.redoPossible | async">
        <button (click)="dataService.redo()" mat-menu-item [attr.aria-label]="'REDO_LAST_ACTION' | translate">
          <mat-icon>redo</mat-icon>
          {{ 'REDO_LAST_ACTION' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="redoService.restorePossible | async">
        <button (click)="dataService.restoreTab()" mat-menu-item [attr.aria-label]="'RESTORE_CLOSED_TAB' | translate">
          <mat-icon>restore_page</mat-icon>
          {{ 'RESTORE_CLOSED_TAB' | translate }}
        </button>
      </ng-container>

      <mat-divider
        *ngIf="(redoService.redoPossible | async) || (redoService.undoPossible | async) || (redoService.restorePossible | async)">
      </mat-divider>

      <button (click)="saveTabOrSelection()" mat-menu-item [attr.aria-label]="'SAVE_TAB' | translate">
        <mat-icon>save</mat-icon>
        {{ 'SAVE_TAB' | translate }}
      </button>

      <button (click)="shareTab()" mat-menu-item  [attr.aria-label]="'SHARE_TAB' | translate"
              [matTooltip]="'EXPERIMENTAL' | translate" matTooltipPosition="left">
        <mat-icon>share</mat-icon>
        {{ 'SHARE_TAB' | translate }}
      </button>

      <button (click)="openEditTabDialog()" mat-menu-item [attr.aria-label]="'EDIT_TAB' | translate">
        <mat-icon>format_paint</mat-icon>
        {{ 'EDIT_TAB' | translate }}
      </button>

      <ng-container *ngIf="dataService.tabs.length > 1">
        <button (click)="dataService.removeCurrentTab()" mat-menu-item [attr.aria-label]="'DELETE_TAB' | translate">
          <mat-icon>delete</mat-icon>
          {{ 'DELETE_TAB' | translate }}
        </button>
      </ng-container>

      <button (click)="clearAllForever()" mat-menu-item [attr.aria-label]="'DELETE_ALL_TABS' | translate">
        <mat-icon>delete_forever</mat-icon>
        {{ 'DELETE_ALL_TABS' | translate }}
      </button>
    </ng-container>
  </mat-menu>

  <!-- Bottom right buttons -->
  <div class="fab-container">
    <button (click)="saveAs(); $event.stopPropagation()" [disabled]="!dataService.itemsCount" class="save-as-button"
            [matTooltip]="'SAVE_AS' | translate" [attr.aria-label]="'SAVE_AS' | translate"
            mat-mini-fab matTooltipPosition="left" color="accent"
            data-cy="save-as-btn">
      <mat-icon>save_as</mat-icon>
    </button>
    <button (click)="save(); $event.stopPropagation()" [disabled]="!dataService.itemsCount"
            [attr.aria-label]="'SAVE'" color="primary" mat-fab data-cy="save-btn">
      <mat-icon>save</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="!settings.isTauri; else tauri">
    <button (click)="showContextMenu($event, true); $event.stopPropagation()"
            [matTooltip]="'MORE' | translate" [attr.aria-label]="'MORE' | translate"
            matTooltipPosition="left" [matTooltipShowDelay]="1000"
            class="context-menu-button" mat-icon-button>
      <mat-icon>menu_open</mat-icon>
    </button>
  </ng-container>
  <ng-template #tauri>
    <button (click)="openFileDialog(); $event.stopPropagation()"
            [matTooltip]="'OPEN_FILE' | translate" [attr.aria-label]="'OPEN_FILE' | translate"
            class="context-menu-button" mat-icon-button matTooltipPosition="left"
            [matTooltipShowDelay]="1000">
      <mat-icon>file_open</mat-icon>
    </button>
  </ng-template>

  <!-- Top right buttons -->
  <button class="settings-button" mat-icon-button [matMenuTriggerFor]="settingsMenu"
          [attr.aria-label]="'SETTINGS' | translate" data-cy="settings-btn">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #settingsMenu="matMenu">
    <button [matMenuTriggerFor]="fontMenu" mat-menu-item [attr.aria-label]="'SWITCH_FONT' | translate">
      <mat-icon>font_download</mat-icon>
      {{ 'SWITCH_FONT' | translate }}
    </button>

    <button [matMenuTriggerFor]="languageMenu" mat-menu-item [attr.aria-label]="'SWITCH_LANGUAGE' | translate">
      <mat-icon>translate</mat-icon>
      {{ 'SWITCH_LANGUAGE' | translate }}
    </button>

    <button *ngIf="settings.animationsDisabled" (click)="settings.animationsDisabled = false" mat-menu-item
            [attr.aria-label]="'ENABLE_ANIMATIONS' | translate">
      <mat-icon>animation</mat-icon>
      {{ 'ENABLE_ANIMATIONS' | translate }}
    </button>
    <button *ngIf="!settings.animationsDisabled" (click)="settings.animationsDisabled = true" mat-menu-item
            [attr.aria-label]="'DISABLE_ANIMATIONS' | translate">
      <mat-icon>motion_photos_off</mat-icon>
      {{ 'DISABLE_ANIMATIONS' | translate }}
    </button>

    <button *ngIf="settings.dblClickMode" (click)="settings.dblClickMode = false" mat-menu-item
            [attr.aria-label]="'DISABLE_DBL_CLICK_MODE' | translate" matTooltipPosition="left"
            [matTooltip]="'DISABLE_DBL_CLICK_MODE_TOOLTIP' | translate">
      <mat-icon>mouse</mat-icon>
      {{ 'DISABLE_DBL_CLICK_MODE' | translate }}
    </button>
    <button *ngIf="!settings.dblClickMode" (click)="settings.dblClickMode = true" mat-menu-item
            [attr.aria-label]="'ENABLE_DBL_CLICK_MODE' | translate" matTooltipPosition="left"
            [matTooltip]="'ENABLE_DBL_CLICK_MODE_TOOLTIP' | translate">
      <mat-icon>mouse</mat-icon>
      {{ 'ENABLE_DBL_CLICK_MODE' | translate }}
    </button>

    <button *ngIf="!settings.lightMode" (click)="settings.lightMode = true" mat-menu-item
            [attr.aria-label]="'ENABLE_LIGHT_MODE' | translate">
      <mat-icon>light_mode</mat-icon>
      {{ 'ENABLE_LIGHT_MODE' | translate }}
    </button>
    <button *ngIf="settings.lightMode" (click)="settings.lightMode = false" mat-menu-item
            [attr.aria-label]="'ENABLE_DARK_MODE' | translate">
      <mat-icon>dark_mode</mat-icon>
      {{ 'ENABLE_DARK_MODE' | translate }}
    </button>

    <ng-container *ngIf="settings.isTauri">
      <button *ngIf="!settings.alwaysOnTop" (click)="settings.alwaysOnTop = true" mat-menu-item
              [attr.aria-label]="'ENABLE_ALWAYS_ON_TOP' | translate">
        <mat-icon>push_pin</mat-icon>
        {{ 'ENABLE_ALWAYS_ON_TOP' | translate }}
      </button>
      <button *ngIf="settings.alwaysOnTop" (click)="settings.alwaysOnTop = false" mat-menu-item
              [attr.aria-label]="'DISABLE_ALWAYS_ON_TOP' | translate">
        <mat-icon>disabled_by_default</mat-icon>
        {{ 'DISABLE_ALWAYS_ON_TOP' | translate }}
      </button>
    </ng-container>

    <a href="https://github.com/jensmeichler/clipboardjesus/issues/new/choose"
       mat-menu-item [target]="dataService._blank"
       [attr.aria-label]="'REPORT_BUG' | translate">
      <mat-icon>bug_report</mat-icon>
      {{ 'REPORT_BUG' | translate }}
    </a>

    <a href="https://github.com/jensmeichler/clipboardjesus/commits" mat-menu-item [target]="dataService._blank"
       [attr.aria-label]="'VIEW_LAST_CHANGES' | translate">
      <mat-icon>published_with_changes</mat-icon>
      {{ 'VIEW_LAST_CHANGES' | translate }}
    </a>

    <a href="https://github.com/sponsors/jensmeichler" mat-menu-item [target]="dataService._blank"
       [attr.aria-label]="'DONATE_TO_THE_DEVELOPER' | translate">
      <mat-icon class="heart">favorite</mat-icon>
      {{ 'DONATE_TO_THE_DEVELOPER' | translate }}
    </a>

    <button (click)="showAboutDialog()" mat-menu-item [attr.aria-label]="'ABOUT_CLIPBOARD' | translate">
      <mat-icon>info</mat-icon>
      {{ 'ABOUT_CLIPBOARD' | translate }}
    </button>

    <button (click)="reloadApp()" mat-menu-item [attr.aria-label]="'RELOAD_APP' | translate">
      <mat-icon>cached</mat-icon>
      {{ 'RELOAD_APP' | translate }}
    </button>
  </mat-menu>

  <mat-menu #languageMenu="matMenu">
    <button class="flags-container" (click)="settings.language = 'en'" mat-menu-item aria-label="English">
      <div class="flag flag-en"></div>
      English
    </button>
    <button class="flags-container" (click)="settings.language = 'de'" mat-menu-item aria-label="Deutsch">
      <div class="flag flag-de"></div>
      Deutsch
    </button>
    <button class="flags-container" (click)="settings.language = 'ua'" mat-menu-item aria-label="Український">
      <div class="flag flag-ua"></div>
      Український
    </button>
  </mat-menu>
  <mat-menu #fontMenu="matMenu">
    <button style="font-family: var(--font-family-victor); font-style: normal"
            (click)="settings.fontFamily = 'Victor Mono'; settings.fontStyle = 'normal'"
            mat-menu-item aria-label="Victor Mono">
      Victor Mono
    </button>
    <button style="font-family: var(--font-family-victor); font-style: italic"
            (click)="settings.fontFamily = 'Victor Mono'; settings.fontStyle = 'italic'"
            mat-menu-item aria-label="Victor Mono italic">
      Victor Mono italic
    </button>
    <button style="font-family: var(--font-family-roboto); font-style: normal"
            (click)="settings.fontFamily = 'Roboto'; settings.fontStyle = 'normal'"
            mat-menu-item aria-label="Roboto">
      Roboto
    </button>
    <button style="font-family: var(--font-family-roboto); font-style: italic"
            (click)="settings.fontFamily = 'Roboto'; settings.fontStyle = 'italic'"
            mat-menu-item aria-label="Roboto italic">
      Roboto italic
    </button>
    <mat-divider></mat-divider>
    <button *ngIf="fetchFontsButtonActive"
            (click)="openSetFontDialog(); $event.stopPropagation()"
            mat-menu-item aria-label="Other font">
      {{ 'MORE' | translate }} ...
    </button>
    <button *ngFor="let font of localFonts"
            [style.font-family]="font.family"
            [style.font-style]="font.style"
            (click)="settings.fontFamily = font.family; settings.fontStyle = font.style"
            mat-menu-item [attr.aria-label]="font.fullName">
      {{ font.fullName }}
    </button>
  </mat-menu>
  <cb-hashy-animated [christmas]="christmas"></cb-hashy-animated>
</div>

<b (click)="showAboutDialog()" class="startup-logo" aria-label="About" data-cy="logo">
  <span (cdkDragEnded)="replaceLogo($event)" cdkDrag style="margin-right: 12px; font-style: italic">Clip</span>
  <img (cdkDragEnded)="replaceLogo($event)" alt="#" cdkDrag height="110"
       src="favicon.ico" width="110">
  <span (cdkDragEnded)="replaceLogo($event)" cdkDrag style="margin-left: 12px; font-style: normal">{{ settings.isBeta ? '_BETA' : 'BOARD' }}</span>
</b>
