<div [style.padding.px]="16"
     [style.color]="data.foregroundColor"
     [style.background]="data.backgroundColorGradient
        ? 'linear-gradient(to bottom, ' + data.backgroundColor + ', ' + data.backgroundColorGradient
        : data.backgroundColor">
  <h1 mat-dialog-title class="flex-container"
      cdkDrag cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane">
    <span>{{ 'EDIT_NOTE' | translate }}</span>
    <a class="flex-container">
      <button *ngIf="!data.reminder" (click)="toggleReminder()" mat-icon-button
              style="margin-right: 10px" matTooltipPosition="above"
              [matTooltipDisabled]="suppressTooltip"
              [matTooltip]="'ADD_REMINDER' | translate"
              [attr.aria-label]="'ADD_REMINDER' | translate">
        <mat-icon>access_alarm</mat-icon>
      </button>
      <button *ngIf="data.reminder" (click)="toggleReminder()" mat-icon-button
              style="margin-right: 10px" matTooltipPosition="above"
              [matTooltipDisabled]="suppressTooltip"
              [matTooltip]="'REMOVE_REMINDER' | translate"
              [attr.aria-label]="'REMOVE_REMINDER' | translate">
        <mat-icon>alarm_off</mat-icon>
      </button>
      <ng-container *ngIf="data.foregroundColor && data.backgroundColor">
        <input [(ngModel)]="data.foregroundColor" style="margin-right: 5px" type="color"
               matTooltipPosition="above" [matTooltip]="'EDIT_TEXT_COLOR' | translate">
        <input [(ngModel)]="data.backgroundColor" style="margin-right: 5px; margin-left: 5px" type="color"
               matTooltipPosition="above" [matTooltip]="'EDIT_BACKGROUND_COLOR' | translate">
        <input [(ngModel)]="data.backgroundColorGradient" style="margin-left: 5px" type="color" matTooltipPosition="above"
               [matTooltip]="(data.backgroundColorGradient ? 'EDIT_GRADIENT_COLOR' : 'ADD_GRADIENT') | translate">
      </ng-container>
    </a>
  </h1>
  <div mat-dialog-content style="margin: 0; padding: 0">
    <div class="form-group-wrapper animate" *ngIf="data.reminder">
      <mat-form-field color="accent">
        <mat-label>{{ 'REMINDER_DATE' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.reminder.date" type="date">
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>{{ 'REMINDER_TIME' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.reminder.time" type="time">
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>{{ 'REMINDER_WARN_BEFORE' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.reminder.before" type="number">
        <span style="margin-left: 6px" matSuffix>m</span>
      </mat-form-field>
    </div>

    <div class="form-group-wrapper">
      <mat-form-field color="accent">
        <mat-label>{{ 'POSITION_X' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.posX" type="number">
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>{{ 'POSITION_Y' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.posY" type="number">
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>{{ 'POSITION_Z' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.posZ" type="number">
      </mat-form-field>
    </div>

    <mat-form-field color="accent" [style.width]="'100%'">
      <mat-label>{{ 'HEADER' | translate }}</mat-label>
      <input data-cy="edit-note-dialog-header" matInput [(ngModel)]="data.header">
    </mat-form-field>

    <mat-form-field color="accent" [style.width]="'100%'">
      <mat-label>{{ 'CONTENT' | translate }}</mat-label>
      <textarea data-cy="edit-note-dialog-content" cdkFocusInitial matInput
                [(ngModel)]="data.content" style="height: 200px"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="dialog-button-group">
    <button mat-button (click)="cancel()" data-cy="edit-note-dialog-cancel">
      {{ 'CANCEL' | translate }}
    </button>
    <button mat-button (click)="submit()" data-cy="edit-note-dialog-submit" class="ok-button">
      {{ 'OK' | translate }}
    </button>
  </div>
</div>
