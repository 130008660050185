<article class="edit-dialog-content" data-cy="edit-tab-dialog">
  <h1 cdkDrag cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane" class="flex-container"
      mat-dialog-title>
    <span class="flex-container">
      <span>{{ 'EDIT_TAB' | translate }}</span>
    </span>
    <a>
      <mat-icon (click)="changeColorTo(green)" [style.color]="green" class="color-button" matTooltipPosition="above"
                [matTooltip]="'SET_COLOR_TO' | translate : { color: green }"
                [attr.aria-label]="'SET_COLOR_TO' | translate : { color: green }">
        color_lens
      </mat-icon>
      <mat-icon (click)="changeColorTo(purple)" [style.color]="purple" class="color-button" matTooltipPosition="above"
                [matTooltip]="'SET_COLOR_TO' | translate : { color: purple }"
                [attr.aria-label]="'SET_COLOR_TO' | translate : { color: purple }">
        color_lens
      </mat-icon>
      <mat-icon (click)="changeColorTo(reset)" [style.color]="reset" class="color-button" matTooltipPosition="above"
                [matTooltip]="'RESET_COLOR' | translate"
                [attr.aria-label]="'RESET_COLOR' | translate">
        format_color_reset
      </mat-icon>
      <input [(ngModel)]="tab.color" type="color" (change)="changeFn()"
             [matTooltip]="(tab.color !== '#131313' ? 'EDIT_BACKGROUND_COLOR' : 'ADD_BACKGROUND_COLOR') | translate"
             matTooltipPosition="above" style="margin: 0 5px">
    </a>
  </h1>
  <div mat-dialog-content style="margin: 0; padding: 0">
    <mat-form-field color="accent" [style.width]="'100%'">
      <mat-label>{{ 'HEADER' | translate }}</mat-label>
      <input cdkFocusInitial [(ngModel)]="tab.label" matInput (input)="changeFn()">
    </mat-form-field>

    <mat-form-field color="accent" [style.width]="'100%'">
      <mat-label>{{ 'BACKGROUND_URL' | translate }}</mat-label>
      <input cdkFocusInitial [(ngModel)]="tab.url" matInput (input)="changeFn()">
    </mat-form-field>

    <div class="flex-container" style="padding: 8px">
      <button (click)="moveToLeft()" [disabled]="dataService.selectedTabIndex < 1"
              mat-icon-button [attr.aria-label]="'MOVE' | translate">
        <mat-icon>chevron_left</mat-icon>
      </button>
      {{ 'TAB_INDEX_I' | translate : { i: dataService.selectedTabIndex } }}
      <button (click)="moveToRight()" [disabled]="dataService.selectedTabIndex >= dataService.tabs.length - 1"
              mat-icon-button [attr.aria-label]="'MOVE' | translate">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div class="dialog-button-group">
    <button mat-button (click)="cancel()">{{ 'CANCEL' | translate }}</button>
    <button mat-button (click)="submit()" class="ok-button">{{ 'OK' | translate }}</button>
  </div>
</article>
