<h1 mat-dialog-title class="dialog-header">
  <span>
    {{ 'ABOUT' | translate }}
    <span style="margin-right: 4px">Clip</span>
    <img alt="#" height="20px" src="favicon.ico" style="transform: translateY(1px)" width="20px">
    <span style="margin-left: 4px">BOARD</span>
    <span *ngIf="settings.isBeta" style="margin-left: 4px" class="beta-button">
      {{ 'BETA' | translate }}
    </span>
    <span *ngIf="settings.isTauri" style="margin-left: 4px" class="beta-button">
      {{ 'DESKTOP' | translate }}
    </span>
  </span>
  <span *ngIf="!settings.isTauri; else tauri">
    <a *ngIf="!settings.isBeta" href="https://jensmeichler.github.io/clipboardjesus/" class="beta-button">
      {{ 'VIEW_BETA' | translate }}
    </a>
    <a *ngIf="settings.isBeta" href="https://www.clipboardjesus.com/" class="beta-button">
      {{ 'VISIT_PRODUCTIVE_VERSION' | translate }}
    </a>
    <ng-container *ngIf="!settings.isBeta && (settings.isWindows || settings.isMacos)">
      <span>|</span>
      <a href="https://github.com/jensmeichler/clipboardjesus/releases"
         class="beta-button" [target]="dataService._blank">
        {{ 'DOWNLOAD_DESKTOP_CLIENT' | translate }}
      </a>
    </ng-container>
  </span>
  <ng-template #tauri>
    <a href="https://www.clipboardjesus.com/" class="beta-button" target="_tauri">
      {{ 'VIEW_WEB_VERSION' | translate }}
    </a>
  </ng-template>
</h1>
<mat-dialog-content style="margin: 0" data-cy="about-dialog">
  <span [innerHTML]="'ABOUT_DIALOG_TEXT_1' | translate"></span>
  <br><br>
  <span [innerHTML]="'ABOUT_DIALOG_TEXT_2' | translate"></span>
  <br><br>
  <span class="thumb">☞&nbsp;</span>
  <a href="https://www.clipboardjesus.com/3rdpartylicenses.txt"
     [attr.aria-label]="'LICENCES' | translate" [target]="dataService._blank">
    <em>{{ 'LICENCES' | translate }}</em>
  </a>

  <div class="hashy-icon-wrapper">
    <cb-hashy></cb-hashy>
    <cb-signature></cb-signature>
  </div>
</mat-dialog-content>
