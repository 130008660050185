<ng-container *ngIf="to">
  <div class="note-connection top-left-to-bottom-right"
       [class.highlight]="from.selected || to.selected"
       *ngIf="from.posY <= to.posY && from.posX <= to.posX"
       [style.top.px]="from.posY"
       [style.left.px]="from.posX"
       [style.height.px]="to.posY - from.posY"
       [style.width.px]="to.posX - from.posX"
  ></div>
  <div class="note-connection bottom-left-to-top-right"
       [class.highlight]="from.selected || to.selected"
       *ngIf="from.posY > to.posY && from.posX <= to.posX"
       [style.top.px]="to.posY"
       [style.left.px]="from.posX"
       [style.height.px]="from.posY - to.posY"
       [style.width.px]="to.posX - from.posX"
  ></div>
  <div class="note-connection top-left-to-bottom-right"
       [class.highlight]="from.selected || to.selected"
       *ngIf="from.posY > to.posY && from.posX > to.posX"
       [style.top.px]="to.posY"
       [style.left.px]="to.posX"
       [style.height.px]="from.posY - to.posY"
       [style.width.px]="from.posX - to.posX"
  ></div>
  <div class="note-connection bottom-left-to-top-right"
       [class.highlight]="from.selected || to.selected"
       *ngIf="from.posY <= to.posY && from.posX > to.posX"
       [style.top.px]="from.posY"
       [style.left.px]="to.posX"
       [style.height.px]="to.posY - from.posY"
       [style.width.px]="from.posX - to.posX"
  ></div>

  <div class="note-bubble"
       [class.highlight]="from.selected || to.selected"
       [style.top.px]="from.posY"
       [style.left.px]="from.posX"
  ></div>
  <div class="note-bubble"
       [class.highlight]="from.selected || to.selected"
       [style.top.px]="to.posY"
       [style.left.px]="to.posX"
  ></div>
</ng-container>
