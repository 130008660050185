<svg height="130" viewBox="0 0 315.13 405.204"
     xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="Ellipse_7">
      <feOffset dx="13" input="SourceAlpha"/>
      <feGaussianBlur result="blur"/>
      <feFlood flood-opacity="0.161" result="color"/>
      <feComposite in="SourceGraphic" in2="blur" operator="out"/>
      <feComposite in="color" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter id="Ellipse_8">
      <feOffset dx="13" input="SourceAlpha"/>
      <feGaussianBlur result="blur-2"/>
      <feFlood flood-opacity="0.161" result="color-2"/>
      <feComposite in="SourceGraphic" in2="blur-2" operator="out"/>
      <feComposite in="color-2" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter id="Ellipse_9">
      <feOffset dx="13" input="SourceAlpha"/>
      <feGaussianBlur result="blur-3"/>
      <feFlood flood-opacity="0.161" result="color-3"/>
      <feComposite in="SourceGraphic" in2="blur-3" operator="out"/>
      <feComposite in="color-3" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter id="Ellipse_10">
      <feOffset dx="13" dy="2" input="SourceAlpha"/>
      <feGaussianBlur result="blur-4"/>
      <feFlood flood-opacity="0.161" result="color-4"/>
      <feComposite in="SourceGraphic" in2="blur-4" operator="out"/>
      <feComposite in="color-4" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter id="Ellipse_11">
      <feOffset dx="13" dy="1" input="SourceAlpha"/>
      <feGaussianBlur result="blur-5"/>
      <feFlood flood-opacity="0.161" result="color-5"/>
      <feComposite in="SourceGraphic" in2="blur-5" operator="out"/>
      <feComposite in="color-5" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter id="Ellipse_12">
      <feOffset dx="9" dy="10" input="SourceAlpha"/>
      <feGaussianBlur result="blur-6"/>
      <feFlood flood-opacity="0.161" result="color-6"/>
      <feComposite in="SourceGraphic" in2="blur-6" operator="out"/>
      <feComposite in="color-6" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter id="Ellipse_13">
      <feOffset dx="8" dy="2" input="SourceAlpha"/>
      <feGaussianBlur result="blur-7"/>
      <feFlood flood-opacity="0.161" result="color-7"/>
      <feComposite in="SourceGraphic" in2="blur-7" operator="out"/>
      <feComposite in="color-7" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="395.778" id="Pfad_1" width="102.33" x="61.014" y="4.052">
      <feOffset dx="-7" dy="1" input="SourceAlpha"/>
      <feGaussianBlur result="blur-8"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-8" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="405.204" id="Pfad_2" width="156.344" x="120.874" y="0">
      <feOffset dx="-7" dy="1" input="SourceAlpha"/>
      <feGaussianBlur result="blur-9"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-9" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="99.861" id="Pfad_3" width="272.709" x="0" y="71.192">
      <feOffset dx="-4" dy="5" input="SourceAlpha"/>
      <feGaussianBlur result="blur-10"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-10" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="136.766" id="Pfad_4" width="264.269" x="11.297" y="141.904">
      <feOffset dx="-6" dy="10" input="SourceAlpha"/>
      <feGaussianBlur result="blur-11"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-11" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="143" id="Ellipse_1" width="106" x="109.392" y="47.117">
      <feOffset dx="-10" dy="-8" input="SourceAlpha"/>
      <feGaussianBlur result="blur-12"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-12" operator="in"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="143" id="Ellipse_1-2" width="106" x="109.392" y="47.117">
      <feOffset dx="-10" dy="-20" input="SourceAlpha"/>
      <feGaussianBlur result="blur-13"/>
      <feFlood flood-opacity="0.161" result="color-8"/>
      <feComposite in="SourceGraphic" in2="blur-13" operator="out"/>
      <feComposite in="color-8" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="143" id="Ellipse_6" width="106" x="39.392" y="60.117">
      <feOffset dx="-10" dy="-8" input="SourceAlpha"/>
      <feGaussianBlur result="blur-14"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-14" operator="in"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="143" id="Ellipse_6-2" width="106" x="39.392" y="60.117">
      <feOffset dx="-2" dy="-25" input="SourceAlpha"/>
      <feGaussianBlur result="blur-15"/>
      <feFlood flood-opacity="0.161" result="color-9"/>
      <feComposite in="SourceGraphic" in2="blur-15" operator="out"/>
      <feComposite in="color-9" operator="in"/>
      <feComposite in2="SourceGraphic" operator="in"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="71.644" id="Ellipse_4" width="60.046" x="142.218" y="75.109">
      <feOffset dx="-3" dy="5" input="SourceAlpha"/>
      <feGaussianBlur result="blur-16"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-16" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="73.126" id="Pfad_7" width="60.152" x="71.779" y="88.554">
      <feOffset dx="-3" dy="5" input="SourceAlpha"/>
      <feGaussianBlur result="blur-17"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-17" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="28.676" id="Pfad_5" width="24.645" x="95.133" y="102.679">
      <feOffset dx="-3" dy="5" input="SourceAlpha"/>
      <feGaussianBlur result="blur-18"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-18" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="31.048" id="Pfad_8" width="25.966" x="164.711" y="87.99">
      <feOffset dx="-3" dy="5" input="SourceAlpha"/>
      <feGaussianBlur result="blur-19"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-19" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter filterUnits="userSpaceOnUse" height="38.594" id="Pfad_9" width="53.691" x="108.822" y="174.583">
      <feOffset dx="-4" dy="5" input="SourceAlpha"/>
      <feGaussianBlur result="blur-20"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite in2="blur-20" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g data-type="innerShadowGroup">
    <ellipse cx="108.602" cy="100.247" data-name="Ellipse 7" fill="#69f0ae" id="Ellipse_7-2" rx="108.602"
             ry="100.247" transform="translate(15.443 95.803) rotate(-7)"/>
    <g filter="url(#Ellipse_7)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="108.602" cy="100.247" data-name="Ellipse 7" fill="#fff" id="Ellipse_7-3" rx="108.602"
               ry="100.247" transform="translate(15.44 95.8) rotate(-7)"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <ellipse cx="108.602" cy="100.247" data-name="Ellipse 8" fill="#69f0ae" id="Ellipse_8-2" rx="108.602"
             ry="100.247" transform="translate(11.921 154.622) rotate(-7)"/>
    <g filter="url(#Ellipse_8)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="108.602" cy="100.247" data-name="Ellipse 8" fill="#fff" id="Ellipse_8-3" rx="108.602"
               ry="100.247" transform="translate(11.92 154.62) rotate(-7)"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <ellipse cx="108.602" cy="100.247" data-name="Ellipse 9" fill="#69f0ae" id="Ellipse_9-2" rx="108.602"
             ry="100.247" transform="translate(7.089 199.173) rotate(-7)"/>
    <g filter="url(#Ellipse_9)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="108.602" cy="100.247" data-name="Ellipse 9" fill="#fff" id="Ellipse_9-3" rx="108.602"
               ry="100.247" transform="translate(7.09 199.17) rotate(-7)"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <ellipse cx="108.602" cy="100.247" data-name="Ellipse 10" fill="#69f0ae" id="Ellipse_10-2" rx="108.602"
             ry="100.247" transform="translate(49.392 200.538) rotate(-7)"/>
    <g filter="url(#Ellipse_10)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="108.602" cy="100.247" data-name="Ellipse 10" fill="#fff" id="Ellipse_10-3" rx="108.602"
               ry="100.247" transform="translate(49.39 200.54) rotate(-7)"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <ellipse cx="108.602" cy="100.247" data-name="Ellipse 11" fill="#69f0ae" id="Ellipse_11-2" rx="108.602"
             ry="100.247" transform="translate(30.129 156.269) rotate(-7)"/>
    <g filter="url(#Ellipse_11)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="108.602" cy="100.247" data-name="Ellipse 11" fill="#fff" id="Ellipse_11-3" rx="108.602"
               ry="100.247" transform="translate(30.13 156.27) rotate(-7)"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <ellipse cx="79.088" cy="80.74" data-name="Ellipse 12" fill="#69f0ae" id="Ellipse_12-2" rx="79.088"
             ry="80.74" transform="translate(138.453 237.261) rotate(-7)"/>
    <g filter="url(#Ellipse_12)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="79.088" cy="80.74" data-name="Ellipse 12" fill="#fff" id="Ellipse_12-3" rx="79.088"
               ry="80.74" transform="translate(138.45 237.26) rotate(-7)"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <ellipse cx="34.803" cy="54.559" data-name="Ellipse 13" fill="#69f0ae" id="Ellipse_13-2" rx="34.803"
             ry="54.559" transform="translate(241.089 271.184) rotate(7)"/>
    <g filter="url(#Ellipse_13)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="34.803" cy="54.559" data-name="Ellipse 13" fill="#fff" id="Ellipse_13-3" rx="34.803"
               ry="54.559" transform="translate(241.09 271.18) rotate(7)"/>
    </g>
  </g>
  <g filter="url(#Pfad_1)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M368.539,473.3C291.7,262.648,351.1,142.766,351.1,142.766" data-name="Pfad 1" fill="none"
          id="Pfad_1-2" stroke="#7b1ea2" stroke-linecap="round" stroke-width="49"
          transform="translate(-236.61 -105.88)"/>
  </g>
  <g filter="url(#Pfad_2)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M368.539,473.3C291.7,262.648,351.1,142.766,351.1,142.766" data-name="Pfad 2" fill="#fff"
          id="Pfad_2-2" stroke="#7b1ea2" stroke-linecap="round" stroke-width="49"
          transform="translate(-189.08 -53.2) rotate(-8)"/>
  </g>
  <g filter="url(#Pfad_3)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M350.429,343.62c-41.473-128.006-9.411-200.855-9.411-200.855" data-name="Pfad 3" fill="none"
          id="Pfad_3-2" stroke="#7b1ea2" stroke-linecap="round" stroke-width="49"
          transform="translate(356.31 -240.43) rotate(86)"/>
  </g>
  <g filter="url(#Pfad_4)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M350.351,326.509c-41.32-117.1-9.377-183.743-9.377-183.743" data-name="Pfad 4" fill="none"
          id="Pfad_4-2" stroke="#7b1ea2" stroke-linecap="round" stroke-width="49"
          transform="translate(303.9 -178.98) rotate(78)"/>
  </g>
  <g data-type="innerShadowGroup">
    <g filter="url(#Ellipse_1)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <g data-name="Ellipse 1" fill="#fff" id="Ellipse_1-3" stroke="#7b1ea2" stroke-width="13"
         transform="translate(119.39 55.12)">
        <ellipse cx="48" cy="67.5" rx="48" ry="67.5" stroke="none"/>
        <ellipse cx="48" cy="67.5" fill="none" rx="41.5" ry="61"/>
      </g>
    </g>
    <ellipse cx="48" cy="67.5" data-name="Ellipse 1" fill="#fff" id="Ellipse_1-4" rx="48"
             ry="67.5" transform="translate(119.392 55.117)"/>
    <g filter="url(#Ellipse_1-2)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="48" cy="67.5" data-name="Ellipse 1" fill="#fff" id="Ellipse_1-5" rx="48"
               ry="67.5" transform="translate(119.39 55.12)"/>
    </g>
    <g data-name="Ellipse 1" fill="none" id="Ellipse_1-6" stroke="#7b1ea2" stroke-width="13"
       transform="translate(119.392 55.117)">
      <ellipse cx="48" cy="67.5" rx="48" ry="67.5" stroke="none"/>
      <ellipse cx="48" cy="67.5" fill="none" rx="41.5" ry="61"/>
    </g>
  </g>
  <g data-type="innerShadowGroup">
    <g filter="url(#Ellipse_6)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <g data-name="Ellipse 6" fill="#fff" id="Ellipse_6-3" stroke="#7b1ea2" stroke-width="13"
         transform="translate(49.39 68.12)">
        <ellipse cx="48" cy="67.5" rx="48" ry="67.5" stroke="none"/>
        <ellipse cx="48" cy="67.5" fill="none" rx="41.5" ry="61"/>
      </g>
    </g>
    <ellipse cx="48" cy="67.5" data-name="Ellipse 6" fill="#fff" id="Ellipse_6-4" rx="48"
             ry="67.5" transform="translate(49.392 68.117)"/>
    <g filter="url(#Ellipse_6-2)" transform="matrix(1, 0, 0, 1, 0, 0)">
      <ellipse cx="48" cy="67.5" data-name="Ellipse 6" fill="#fff" id="Ellipse_6-5" rx="48"
               ry="67.5" transform="translate(49.39 68.12)"/>
    </g>
    <g data-name="Ellipse 6" fill="none" id="Ellipse_6-6" stroke="#7b1ea2" stroke-width="13"
       transform="translate(49.392 68.117)">
      <ellipse cx="48" cy="67.5" rx="48" ry="67.5" stroke="none"/>
      <ellipse cx="48" cy="67.5" fill="none" rx="41.5" ry="61"/>
    </g>
  </g>
  <g filter="url(#Ellipse_4)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <ellipse cx="21.5" cy="28.5" data-name="Ellipse 4" fill="#7b1ea2" id="Ellipse_4-2" rx="21.5"
             ry="28.5" transform="translate(145.22 86.96) rotate(-16)"/>
  </g>
  <g filter="url(#Pfad_7)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path
      d="M22.1.311C34.286.486,44.326,13.881,44.524,30.23S35,59.689,22.815,59.514.588,45.943.39,29.595,9.912.136,22.1.311Z"
      data-name="Pfad 7"
      fill="#7b1ea2"
      id="Pfad_7-2" transform="translate(74.33 99.03) rotate(-14)"/>
  </g>
  <g filter="url(#Pfad_5)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M0,0S2.969-.18,7.737,10.164" data-name="Pfad 5" fill="none"
          id="Pfad_5-2" stroke="#fff" stroke-linecap="round" stroke-width="11"
          transform="matrix(1, -0.03, 0.03, 1, 104.14, 108.7)"/>
  </g>
  <g filter="url(#Pfad_8)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M0,0S3.461-.222,9.018,12.553" data-name="Pfad 8" fill="none"
          id="Pfad_8-2" stroke="#fff" stroke-linecap="round" stroke-width="11"
          transform="matrix(1, -0.03, 0.03, 1, 173.74, 94.06)"/>
  </g>
  <g filter="url(#Pfad_9)" transform="matrix(1, 0, 0, 1, 0, 0)">
    <path d="M342.295,170.384c-25.585-17.6-5.806-27.619-5.806-27.619" data-name="Pfad 9" fill="none"
          id="Pfad_9-2" stroke="#fff" stroke-linecap="round" stroke-width="15"
          transform="translate(-36.51 519.88) rotate(-87)"/>
  </g>
</svg>
